var data = {};

//Dati della visita attuale
var new_session = false;
var session_object = {};

//Documento pronto
document.addEventListener("DOMContentLoaded", function() {
    printBannersAndRecord();
});

//Quando usciamo dalla pagina
window.onbeforeunload = function() {
    //Imposto la data di fine sessione
    session_object.session_end = formatDate(new Date());
    //Salvo i dati utente
    localStorage.setItem('mediaplex_local', JSON.stringify(data));
};

Object.size = function(obj) {
    var size = 0,
        key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

function formatDate(data) {
    var formatted_date = data.getFullYear() + "-" + (data.getMonth() + 1) + "-" + data.getDate() + " " + ("0" + data.getHours()).slice(-2) + ":" + ("0" + data.getMinutes()).slice(-2) + ":" + ("0" + data.getSeconds()).slice(-2);
    return formatted_date;
}

function printBannersAndRecord() {
    /*
    let checkSessionPromise = checkSessions();
    checkSessionPromise.then(s => {
        //Se l'utente ha già visitato compass
        var banner_container = document.querySelector('.swiper-slide.ads');
        if (Object.size(s) > 0) {
            banner_container.innerHTML = '<img src="/application/themes/compass/images/demo/banner_promo.jpg" />';
        } else {
            var parent = banner_container.parentElement;
            parent.removeChild(banner_container);
        }
        if (typeof window.cmp_hero != "undefined") {
            window.cmp_hero.update();
        }
        recordSessionData();
        recordCompassData();
    });
    */
}

function checkSessions() {
    return new Promise(function(resolve, reject) {
        try {
            var mp = localStorage.getItem("mediaplex_local");
            var sessions = {};
            if (mp != null) {
                var data = JSON.parse(mp);
                if (typeof data.sessions != "undefined" && data.sessions != null && Object.size(data.sessions) > 0) {
                    sessions = data.sessions;
                }
            }
            resolve(sessions);
        } catch (e) {
            reject(e);
        }
    });
}

function recordCompassData() {
    document.body.addEventListener("click", function(e) {
        try {
            var c_element = e.target;
            var target_element = c_element;
            if (c_element.classList.value.indexOf('map-found-row-item') == -1) {
                target_element = target_element.closest('.map-found-row-item');
            }
            var elems = target_element.querySelectorAll('.txt-branche-desc');
            var correct_elem = elems.length > 0 ? elems[0] : null;
            var objKey = correct_elem.innerHTML.replace(/ /g, '-');
            if (typeof data.filiali == "undefined") {
                data.filiali = {};
            }
            if (typeof data.filiali[objKey] == "undefined") {
                data.filiali[objKey] = {
                    'name': correct_elem.innerHTML,
                    'views': 1
                };
            } else {
                data.filiali[objKey]['views']++;
            }
            localStorage.setItem('mediaplex_local', JSON.stringify(data));
        } catch (e) {
            console.log("Mediaplex error:");
            console.log(e);
        }
    });
}

function recordSessionData() {
    try {
        //Recupero i dati locali dell'utente
        var local_data = localStorage.getItem('mediaplex_local');
        //Nel caso fossero presenti dei dati
        if (local_data != null) {
            //Decodifico i dati e li assegno a data
            data = JSON.parse(local_data);
        }
        if (!data.hasOwnProperty('sessions')) { //Non ho ancora il nodo, quindi è la prima visita
            data.sessions = {}; //Creo il nodo a cui aggiungere le visite
        }
        //Controllo se era una sessione già avviata o una nuova sessione
        if (sessionStorage.getItem("mediaplex_session_active") == null) {
            new_session = true; //E' una nuova sessione
            //Imposto la sessione come attiva
            sessionStorage.setItem("mediaplex_session_active", true);
            //Aggiungo la data di inizio sessione al nostro oggetto view
            session_object.session_start = formatDate(new Date());
        } else {
            //Recupero la sessione precedente
            for (let k in data.sessions) {
                var cur_session = data.sessions[k];
                var date_cur = new Date(cur_session.session_start);
                var date_old = new Date(session_object.session_start);
                if (typeof session_object.session_start == "undefined" || date_cur > date_old) {
                    session_object = cur_session;
                }
            }
        }
        //Nel caso non siano già state visualizzate pagine
        if (typeof session_object.pages == "undefined") {
            session_object.pages = {}; //Creo l'oggetto pagine
        }
        //Nel caso non siano stati aggiunti tag
        if (typeof session_object.tags == "undefined") {
            session_object.tags = {}; //Creo l'oggetto tags
        }
        //Registro i tags della pagina corrente
        var tags = getKeywords();
        for (var k in tags) {
            if (typeof session_object.tags[tags[k]] == "undefined") {
                session_object.tags[tags[k]] = {
                    'tag': tags[k],
                    'views': 1
                };
            } else {
                session_object.tags[tags[k]]["views"]++;
            }
        }
        //Nel caso non sia stata visualizzata la pagina corrente
        if (typeof session_object.pages[document.location.pathname] == "undefined") {
            //Creo l'oggetto pagina corrente
            session_object.pages[document.location.pathname] = {
                'path': document.location.pathname,
                'first_view': formatDate(new Date()),
                'last_view': formatDate(new Date()),
                'views': 1
            };
        } else { //Abbiamo precedentemente visualizzato questa pagina
            //Incremento le visualizzazioni pagina
            session_object.pages[document.location.pathname]['views']++;
            //Imposto adesso come ultima visita
            session_object.pages[document.location.pathname]['last_view'] = formatDate(new Date());
        }
        //Aggiungo la sessione corrente ai dati utente
        if (new_session) {
            var session_guid = makeGuid();
            session_object.guid = session_guid;
            data.sessions[session_guid] = session_object;
        } else {
            data.sessions[session_object.guid] = session_object;
        }
        //Salvo i dati utente
        localStorage.setItem('mediaplex_local', JSON.stringify(data));
    } catch (e) { //Segnalo l'errore generico
        console.log("Mediaplex error:");
        console.log(e);
    }
}

function makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

function makeGuid() {
    return makeid() + '-' + makeid() + '-' + makeid() + '-' + makeid();
}

function getKeywords() { //Restituisce un array contenente le keywords della pagina
    var metas = document.getElementsByTagName('meta');
    for (var i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") == "keywords") {
            var keywords = metas[i].getAttribute("content").split(",");
            for (var k in keywords) {
                keywords[k] = keywords[k].trim();
            }
            return keywords;
        }
    }
    return [];
}